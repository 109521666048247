.chatContainer {
    display: flex;
    flex-direction: column;
}

@keyframes ripple {
    0% {
        transform: scale(1);
        opacity: .3;
    }

    50%,
    100% {
        transform: scale(1.4);
        opacity: 0;
    }

}

.callScreen {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    .loaderGif {
        mix-blend-mode: plus-lighter;
    }
}

.buttonsContainer {
    position: absolute;
    z-index: 2;
    bottom: 44px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;

    button {
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        height: 64px;
        width: 64px;
        font-size: 1.5rem;
        background-color: var(--color-primary-alpha);
        color: var(--color-primary);

        &.hangupButton {
            height: 80px;
            width: 80px;
            font-size: 2rem;
            border-radius: 50%;
            background-color: var(--color-red-alpha);
            color: var(--color-red);
        }
    }
}

.conversationContainer {
    width: 100%;
    height: calc(100% - 104px - 24px);

    &:before {
        content: "";
        position: absolute;
        z-index: 2;
        pointer-events: none;
        top: 0;
        left: 0;
        width: 100%;
        height: 100px;
        /* background: linear-gradient(180deg, rgba(13, 14, 49, .8) 0%, rgba(13, 14, 49, 0) 80%); */
    }

    .messages {
        width: 100%;
        height: 100%;
        max-height: 100%;
        min-height: 100%;
        display: flex;
        flex: 1;
        flex-direction: column;
        overflow-y: auto;
        padding: 32px 20px;

        .messageList {
            margin-top: auto;
        }

        .message {
            display: flex;
            flex-direction: column;
            width: 100%;
            max-width: 768px;
            margin-left: auto;
            margin-right: auto;
            padding: 0 24px;

            &.ai {
                align-items: flex-start;
            }

            &.user {
                align-items: flex-end;

                .messageContent {
                    display: inline-block;
                    background-color: #5DA5F0;
                    max-width: 70%;
                    color: #0A1929;
                }
            }

            &:not(:last-child) {
                margin-bottom: 16px;
            }

            .time {
                padding: 0 16px;
                font-size: 0.625rem;
                display: block;
                color: #B0B3C6;
                margin-bottom: 8px;
            }

            .messageContent {
                padding: 16px;
                border-radius: 8px;
            }
        }
    }
}

.keyboardContainer {
    width: 100%;
    height: max-content;
    max-width: 768px;
    margin-left: auto;
    margin-right: auto;

    .inputContainer {
        position: relative;
        height: max-content;
        border-radius: 24px;
        display: flex;
        align-items: center;
        background-color: #1A2A40;
        border: 1px solid #2A3E54;
        border-radius: 32px;

        textarea {
            border: none;
            width: calc(100% - 40px - 24px - 11px);
            min-height: 104px;
            padding-left: 24px;
            padding-right: 12px;
            padding-top: 24px;
            padding-bottom: 24px;
            max-height: 600px;
            overflow-y: auto;
            word-break: break-all;
            scrollbar-width: auto;
            scrollbar-color: #1A2A40;
            *::-webkit-scrollbar {
                width: 8px;
              }
            &::-webkit-scrollbar-track {
                background: #1A2A40;
            }

            &::-webkit-scrollbar-thumb {
                background-color: rgba(93, 164, 240, 0.3);
                border-top: 28px solid #1A2A40;
                border-bottom: 28px solid #1A2A40;
            }
            &::-webkit-scrollbar-thumb:hover {
                background-color: rgba(93, 164, 240, 0.7);
            }
        }

        button {
            position: absolute;
            bottom: 32px;
            right: 24px;
        }
    }
}

@media screen and (max-width: 768px) {
    .conversationContainer {
        height: calc(100% - 100px);
    }

    .keyboardContainer {
        margin-bottom: 0;

        .inputContainer {
            border-radius: 0;
            background-color: #3A4475;
        }
    }

}


.voiceSlider {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background-color: #0D0F1A;

    .closeSlider {
        position: absolute;
        top: 16px;
        right: 16px;
        cursor: pointer;
        color: #B0B3C6;
        height: 44px;
        width: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.5rem;
        border-radius: 50%;
        background-color: rgba(176, 179, 198, 0.2);
    }

    .prevButton {
        position: absolute;
        top: 50%;
        left: 16px;
        transform: translateY(-50%);
        cursor: pointer;
        color: #B0B3C6;
        height: 44px;
        width: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.5rem;
        border-radius: 50%;
        background-color: rgba(176, 179, 198, 0.2);
    }

    .nextButton {
        position: absolute;
        top: 50%;
        right: 16px;
        transform: translateY(-50%);
        cursor: pointer;
        color: #B0B3C6;
        height: 44px;
        width: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.5rem;
        border-radius: 50%;
        background-color: rgba(176, 179, 198, 0.2);
    }

    .confirmButton {
        position: absolute;
        bottom: 40px;
        left: 50%;
        transform: translateX(-50%);
        cursor: pointer;
    }

    .voiceDisplay {
        position: absolute;
        top: calc(50% + 100px);
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 16px;

        h2 {
            font-size: 2.5rem;
            color: #9CFFFF;
        }

        .dotnav {
            display: flex;
            gap: 8px;
            margin-top: 16px;

            li {
                height: 16px;
                width: 16px;
                border-radius: 50%;
                background-color: rgba(176, 179, 198, 0.2);
                cursor: pointer;

                &.active {
                    background-color: #9CFFFF;
                }
            }
        }
    }

    .voiceImage {
        position: absolute;
        top: calc(50% - 50px);
        left: 50%;
        transform: translate(-50%, -50%);
        height: 400px;
        width: auto;
        display: block;
        mix-blend-mode: plus-lighter;
        pointer-events: none;
        user-select: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -o-user-select: none;
    }
}
.test {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;

    label {
        display: block;
        font-size: 1.5rem;
        font-weight: 600;
        margin-bottom: 8px;
    }
    select {
        border: 1px solid #fff;
        display: block;
        width: 100%;
        padding: 16px;
        text-transform: capitalize;

        option {
            background-color: #101036;
        }
    }
    .ctaContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 24px;
        gap: 16px;
        button {
            min-width: 0;
            &:first-child {
                color: #fff;
                background-color: var(--color-primary-alpha);
            }
            &:last-child {
                color: var(--color-red);
                background-color: var(--color-red-alpha);
            }
        }
    }

    .loaderContainer {
        width: 600px;
        height: 400px;
        position: relative;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
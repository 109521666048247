.monthHeatmapContainer {
    /* Display days in a 7-column grid (like a calendar) */
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    gap: 8px; /* space between day squares */
    padding: 24px 8px;
    width: 100%;
  }
  
  .dayBox {
    width: 100%;
    aspect-ratio: 1 / 1;
    font-weight: 600;
    font-size: 0.825rem;
    border-radius: 4px;
    color: #fff;
    font-weight: bold;
    padding: 6px 8px;
  }
  
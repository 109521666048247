.legalsModal {
    .modal {
        overflow: hidden;

        .modalHeader {
            margin-bottom: 0;
        }

        .modalContent {
            overflow-y: auto;
            height: calc(100% - 77px);

            .contentScroll {
                padding: 24px;
            }
        }
    }

    h2 {
        margin-bottom: 8px;
    }
    strong {
        font-weight: 600;
        font-size: 0.875rem;
        color: #A8C5D6;
        text-transform: uppercase;
        letter-spacing: 1px;
    }

    ul {
        list-style: inside;
        li {
            &:not(:last-child) {
                margin-bottom: 8px;
            }
        }
    }
}
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap');
/* LOAD FONT AWESOME */
@import url("https://kit.fontawesome.com/a972641f5a.css");

:root {
  --color-bg1: #081522;
  --color-bg2: #0a1929;
  --color1: 15, 30, 53;
  --color2: 15, 30, 53;
  --color3: 15, 30, 53;
  --color4: 15, 30, 53;
  --color5: 15, 30, 53;
  --circle-size: 90%;
  --blending: hard-light;
  --color-primary: #5ca3f0;
  --color-blue: #1A5F9E;
  --color-green: #55F05B;
  --color-yellow: #FFC75A;
  --color-red: #FF5A5A;
  --color-black: #081522;
  --color-lightblue: #e8f0f7;
  --color-black-alpha: hsla(211, 61%, 10%, 0.6);
  --color-primary-alpha: hsla(211, 83%, 65%, 0.3);
  --color-green-alpha: hsla(122, 78%, 62%, 0.3);
  --color-yellow-alpha: hsla(39, 78%, 62%, 0.3);
  --color-red-alpha: hsla(0, 78%, 62%, 0.3);
}


* {
  margin: 0;
  padding: 0;
  outline: none;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box;
  border: none;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

body {
  background: var(--color-black);
  color: #C0D4E6;
  font-family: "Source Sans 3", serif;
  font-optical-sizing: auto;
  font-style: normal;
  overflow: hidden;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.4;

  @media screen and (max-width: 768px) {
    font-size: 14px;
  }

  &.homepage-active, &.connect-active {
    overflow-y: auto;
    overflow-x: hidden;
    #root {
      height: auto;
    }
  }
}

h1,
h2,
h3 {
  font-family: "Poppins", serif;
  font-weight: 600;
  font-style: normal;
  color: #E0F2F2;
}

label {
  display: block;
  width: 100%;
}

input {
  display: block;
  width: 100%;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  padding: 8px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  background: transparent;
  transition: border-color 0.2s ease-in-out;

  &:focus {
    border-color: rgba(255, 255, 255, 0.8);
  }

  &:not(:last-child) {
    margin-bottom: 32px;
  }

  &:not(:placeholder-shown) {
    color: rgba(255, 255, 255, 1);
  }
}

*::placeholder {
  color: rgba(255, 255, 255, 0.4);
}

main {
  height: 100%;
  width: 100%;
  display: flex;
  overflow-y: auto;
  overflow-x: hidden;
}

.wrap {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;

  &.wrap-XL {
    max-width: 1400px;
  }

  &.wrap-L {
    max-width: 75%;
    max-width: 1280px;
  }

  &.wrap-M {
    max-width: 800px;
  }

  &.wrap-S {
    max-width: 400px;
  }

  @media screen and (max-width: 1440px) {
    &.wrap-XL {
      max-width: 90%;
    }
    &.wrap-L {
      max-width: 90%;
    }
  }

  @media screen and (max-width: 1024px) {
    &.wrap-L, &.wrap-M, &.wrap-S {
      max-width: 90%;
    }
  }
}

.mt8 {
  margin-top: 8px;
}

.mt16 {
  margin-top: 16px;
}

.mt24 {
  margin-top: 24px;
}

.mt32 {
  margin-top: 32px;
}

.mt40 {
  margin-top: 40px;
}

.mt60 {
  margin-top: 60px;
}

.mb8 {
  margin-bottom: 8px;
}

.mb16 {
  margin-bottom: 16px;
}

.mb24 {
  margin-bottom: 24px;
}

.mb32 {
  margin-bottom: 32px;
}

.mb40 {
  margin-bottom: 40px;
}

.mb60 {
  margin-bottom: 60px;
}


.mr8 {
  margin-right: 8px;
}

.mr16 {
  margin-right: 16px;
}

.mr24 {
  margin-right: 24px;
}

.mr32 {
  margin-right: 32px;
}

.mr40 {
  margin-right: 40px;
}


.ml8 {
  margin-left: 8px;
}

.ml16 {
  margin-left: 16px;
}

.ml24 {
  margin-left: 24px;
}

.ml32 {
  margin-left: 32px;
}

.ml40 {
  margin-left: 40px;
}


.flex {
  display: flex;
}

.alignCenter {
  align-items: center;
}

.justifyCenter {
  justify-content: center;
}

.flexRow {
  flex-direction: row;
}

.flexCol {
  flex-direction: column;
}

.flexColRev {
  flex-direction: column-reverse;
}

.flexRowRev {
  flex-direction: row-reverse;
}

.flexWrap {
  flex-wrap: wrap;
}

.flexWrapRev {
  flex-wrap: wrap-reverse;
}

.flexWrapRev {
  flex-wrap: wrap-reverse;
}

.gap8 {
  gap: 8px;
}

.gap16 {
  gap: 16px;
}

.gap24 {
  gap: 24px;
}

.gap32 {
  gap: 32px;
}

.gap40 {
  gap: 40px;
}

.gap60 {
  gap: 60px;
}


a {
  cursor: pointer;
  text-decoration: underline;
  color: var(--color-primary);
}


.tal {
  text-align: left;
}

.tar {
  text-align: right;
}

.tac {
  text-align: center;
}


.cta {
  display: inline-block;
  padding: 8px 48px;
  font-size: 16px;
  font-weight: 700;
  color: #0A1929;
  background: hsl(211, 83%, 65%);
  border-radius: 14px;
  text-align: center;
  cursor: pointer;
  min-width: 160px;
  box-shadow: inset 0 0px 0px 2px rgba(255, 255, 255, 0.1), 0 1px 2px hsl(211, 83%, 20%);

  &:disabled {
    background-color: #2A3E54;
    box-shadow: inset 0 0px 0px 2px rgba(255, 255, 255, 0.1), 0 0 1px 2px rgba(42, 62, 84, 0.2);
    opacity: 0.6;
  }

  &.circle {
    min-width: 0;
    width: 40px;
    height: 40px;
    border-radius: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
  }
}


.preventSelect {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
}


.inputReveal {
  position: relative;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;

  i {
    position: absolute;
    z-index: 2;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    opacity: .3;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }
}

.error-message {
  background-color: var(--color-red-alpha);
  padding: 8px 16px;
  border-radius: 8px;
  color: var(--color-red);
  font-size: 14px;
  margin-top: 8px;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  font-weight: 400;
  text-align: center;
}

.modalContainer {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    background-color: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
  }

}


.modal {
  background-color: hsl(211, 45%, 14%);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 90%;
  width: 90%;
  max-height: 600px;
  max-width: 680px;
  border-radius: 16px;
  z-index: 2;
}

.modalHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1em 1.5em;
  border-bottom: 1px solid rgba(255, 255, 255, 0.075);
  margin-bottom: 1em;

  button {
    cursor: pointer;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: hsl(211, 45%, 18%);
    }
  }
}

.modalBody {
  display: flex;
  nav {
    font-size: 1rem;
    width: 180px;

    li {
      cursor: pointer;
      position: relative;
      padding: 4px 8px;

      i {
        margin-right: 4px;
        font-size: 0.8rem;
      }
      button {
        padding: 8px 12px;
        border-radius: 8px;
        width: 100%;
      }
      &.active {
        button {
          background-color: #1A2A40;
        }
      }
    }
  }

  .modalContent {
    padding: .5em 1.5em;
    overflow-y: auto;
    height: 100%;
  }
}

.cup {
  cursor: pointer;
}


/* =====
  SCROLLBAR
===== */

/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: hsl(231, 33%, 20%);
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  background: #1A2A40;
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(93, 164, 240, 0.3);
}

*::-webkit-scrollbar-thumb:hover {
  background-color: rgba(93, 164, 240, 0.7);
}


/* =====
  END SCROLLBAR
===== */
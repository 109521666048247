.modeContainer {
    position: fixed;
    z-index: 99;
    top: 8px;
    right: 16px;
    cursor: pointer;
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 44px;
        height: 44px;
    }
    
    i {
        color: #fff;
        font-size: 24px;
    }
    svg {
        height: 44px;
        width: 44px;
    }
    
}
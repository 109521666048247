.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px;
    width: 600px;
    position: relative;
    mix-blend-mode: plus-lighter;
    pointer-events: none;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    transition: transform 0.5s;

    &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(-45deg) scaleY(0);
        width: 4px;
        height: 60%;
        background: rgb(255, 255, 255);
    }

    img {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    &.muted {
        transform: scale(0.5);

        &:after {
            transform: translate(-50%, -50%) rotate(-45deg) scaleY(1);
            transition: transform 0.5s;
        }
    }
}
.mainHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 8px);
    height: 80px;
    padding: 0 3%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    font-size: 16px;
    overflow: hidden;

    &:after {
        content: "";
        background-color: var(--color-black);
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        transform: translateY(-100%);
        transition: transform 0.2s ease-in-out;
    }

    &.scrolled {
        &:after {
            transform: translateY(0);
        }
    }

    >svg {
        height: 20px;
    }

    ul {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-weight: 600;

        li {
            cursor: pointer;
        }
    }

    @media screen and (max-width: 1024px) {
        padding: 0 32px;

        >svg {
            height: 25px;
        }
    }
}

.homeMain {
    height: 100vh;

    .wrap {
        justify-content: space-between;
        flex-direction: row;
        max-width: 60%;
        gap: 5%;
    }

    .imageContainer {
        position: relative;
        transform: rotate(8deg);
        width: 30%;

        .mockup {
            position: relative;
            width: 100%;
        }

        .loader {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 2;
            width: 89%;
            object-fit: contain;
            mix-blend-mode: lighten;
        }
    }

    .content {
        width: 60%;

        h1 {
            font-size: clamp(2rem, 5vw, 4rem);
            margin-bottom: 40px;

            span {
                color: var(--color-primary);
                position: relative;

                &:after {
                    content: "";
                    display: inline;
                    width: 3px;
                    height: 90%;
                    background-color: var(--color-primary);
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: -10px;
                }

                &.paused {
                    &:after {
                        animation: animatePipe 1s linear infinite;
                    }
                }
            }
        }

        p {
            line-height: 1.6;
        }
    }

    @media screen and (max-width: 1500px) {
        .wrap {
            .imageContainer {
                width: 30%;
            }

            .content {
                width: 65%;

                h1 {
                    font-size: clamp(2rem, 5vw, 3rem);
                }
            }
        }
    }

    @media screen and (max-width: 1024px) {
        .wrap {
            max-width: 85%;
            flex-direction: column-reverse;
            justify-content: center;
            align-items: center;
            gap: 0;

            .content {
                width: 100%;
                margin-right: auto;
                margin-left: auto;
                text-align: center;

                h1 {
                    margin-bottom: 30px;
                    font-size: clamp(2rem, 5vw, 4rem);

                    span {
                        height: 80px;
                    }
                }

                p {
                    font-size: 1.25em;
                }
            }

            .imageContainer {
                transform: none;
                width: 100%;

                .mockup {
                    display: none;
                }

                .loader {
                    position: relative;
                    aspect-ratio: 2/1;
                    height: 150px;
                    width: 100%;
                    object-fit: cover;
                }
            }
        }
    }
}


.typing-container {
    position: relative;
    overflow: hidden;
}

@media (max-width: 768px) {
    .typing-container {
        font-size: 1em;
    }
}

@keyframes animatePipe {

    0%,
    49% {
        opacity: 1;
    }

    50%,
    100% {
        opacity: 0;
    }
}




.homeSection {
    background-color: var(--color-lightblue);

    .wrap {
        padding: 10vh 0;
    }

    h2 {
        color: #2A3A4D;
        font-size: 40px;
    }

    h3 {
        color: #2A3A4D;
        font-size: 24px;
    }

    &.sectionTechnology {
        h2 {
            font-size: 40px;
            max-width: 540px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 60px;
            text-align: center;
        }

        ul {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 40px;

            li {
                background-color: #FFFFFF;
                padding: 32px 40px;
                border-radius: 20px;
                display: flex;
                flex-direction: column;
                gap: 20px;
                border: 1px solid #C0D0E0;

                p {
                    color: #6B7D8F;
                }

                i {
                    color: #5A7A85;
                }
            }

            @media screen and (max-width: 1024px) {
                grid-template-columns: 1fr;
            }
        }
    }

    &.sectionWhy {
        .wrap {
            align-items: flex-start;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }

        .imgContainer {
            background-color: #081522;
            width: 50%;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .content {
            padding-right: 5%;

            h2 {
                margin-bottom: 60px;
            }

            ul {
                .whyElement {
                    cursor: pointer;

                    &:not(:last-of-type) {
                        border-bottom: 1px solid #C0D0E0;
                        padding-bottom: 24px;
                        margin-bottom: 24px;
                    }

                    h3 {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        i {
                            font-size: 0.825em;
                            margin-right: 12px;
                            color: #5A7A85;
                        }
                    }

                    .content {
                        color: #5A7185;
                        max-height: 0;
                        overflow: hidden;

                        h4 {
                            font-size: 1.25em;
                            margin-bottom: 16px;
                            color: #2A3A4D;
                            font-weight: 600;
                        }

                        ul {
                            li {
                                &:before {
                                    content: "•";
                                    color: #5A7185;
                                    font-weight: bold;
                                    display: inline-block;
                                    margin-right: 6px;
                                }

                                &:not(:last-of-type) {
                                    margin-bottom: 8px;
                                }
                            }
                        }
                    }

                    &.active {
                        h3 {
                            color: var(--color-primary);

                            i {
                                color: var(--color-primary);
                            }
                        }

                        .content {
                            margin-top: 16px;
                            max-height: 1000px;
                        }
                    }
                }
            }
        }

        @media screen and (max-width: 1024px) {
            .wrap {
                flex-direction: column;
                gap: 0;
            }

            .imgContainer {
                display: none;
            }

            .content {
                width: 100%;
                padding-right: 0;
            }
        }
    }
}

.homeFooter {
    position: relative;

    .wrap {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20vh 0;

        >a {
            display: block;
            font-size: clamp(1.25rem, 5vw, 4rem);
            color: var(--color-primary);
            text-decoration: none;
            font-weight: 600;
        }

        ul {
            display: flex;
            flex-wrap: wrap;
            gap: 40px;
            text-transform: uppercase;
            font-size: 0.825em;
            letter-spacing: 0.5px;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            text-align: center;
            align-items: center;
            justify-content: center;
            padding: 16px 0;

            li {

                p,
                a {
                    color: #A8C5D6;
                    text-decoration: none;
                }
            }

            @media screen and (max-width: 1024px) {
                flex-direction: column-reverse;
                gap: 16px;
                position: static;
                margin-top: 30px;
            }
        }

        @media screen and (max-width: 1024px) {
            padding: 50px 0;
        }
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-24px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.fadeIn {
    transform: translateY(-24px);
    opacity: 0;
    animation-name: fadeIn;
    animation-duration: 0.1s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
    display: inline-block;
}
.loginMain {
    padding: 60px 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 100vh;


    >.wrap {
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        width: 100%;
        height: 100%;

        >div {
            width: 100%;
        }

        .form {
            h1 {
                font-size: 2rem;
                margin-bottom: 16px;
                text-align: center;
            }

            .subTitle {
                margin-bottom: 60px;
                text-align: center;
            }
        }
    }

    &.verifySuccess {
        .form {
            h1 {
                color: var(--color-green);
            }
        }
    }
}


.codeContainer {
    display: flex;
    gap: 4px;
    margin-bottom: 30px;
    margin-top: 32px;
    align-items: center;
    justify-content: center;

    input {
        width: 50px;
        font-size: 1.625rem;
        text-align: center;
        margin-bottom: 0;
        border: 1px solid var(--color-primary-alpha);
        border-radius: 8px;
        font-weight: 600;
        backdrop-filter: blur(8px);
        padding: 12px 0;
        position: relative;

        &.filled {
            background-color: #1a2a40;
            border: 2px solid var(--color-primary);
            color: var(--color-white);
        }
    }

    .separator {
        margin: 0 8px;
        display: inline-block;
        height: 2px;
        width: 16px;
        background: var(--color-primary-alpha);
    }

    &.success {
        input {
            background-color: #1a2a40;
            border: 2px solid var(--color-green);
            color: var(--color-green);
        }
    }
}


.bgVideo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    filter: hue-rotate(133deg) invert(1) brightness(0.6) contrast(3) saturate(0.9);
    mix-blend-mode: exclusion;
    opacity: 0.2;

    video {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.forgotPassword {
    color: #A8C5D6;
    font-size: 0.875rem;
}

.legals {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 24px;

    li {
        color: #A8C5D6;
        font-size: 0.625rem;
        text-transform: uppercase;
        text-decoration: none;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }
}
.confirmModal {
    background-color: hsl(211, 45%, 14%);
    position: fixed;
    z-index: 999;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 90%;
    width: 90%;
    max-height: 300px;
    max-width: 400px;
    border-radius: 16px;
    padding: 16px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;

    h2 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 8px;
        span {
            display: inline-block;
            padding: 8px 16px;
            background-color: hsl(215, 42%, 22%);
            color: #FFF;
            border-radius: 8px;
            font-size: 0.8rem;
            font-style: italic;
        }
    }

    .actions {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        gap: 16px;

        .cta {
            min-width: 0;
            padding: 8px 24px;
            &.cancel {
                background-color: hsl(0, 0%, 100%);
                color: hsl(211, 45%, 14%);
            }
            &.confirm {
                background-color: hsl(211, 45%, 14%);
                color: hsl(0, 0%, 100%);
            }
        }
    }

    &.forgotPassword {
        &.success {
            i {
                height: 60px;
                width: 60px;
                background-color: var(--color-green);
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #FFF;
            }
        }
    }
}
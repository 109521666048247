:root {
    --gradient-color-1: #222744;
    --gradient-color-2: #5ca3f0;
    --gradient-color-3: #5da4f0;
    --gradient-color-4: #081522;
  }
  

#bgCanvas {
    position: fixed;
    z-index: -1;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    top: 0;
    left: 0;
    opacity: 0.1;
}
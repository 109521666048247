.menuIcon {
    position: fixed;
    z-index: 101;
    top: 8px;
    left: 16px;
    cursor: pointer;
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease-in-out;
}

.menuIcon .icon {
    position: relative;
}

.menuIcon .icon span {
    display: block;
    width: 24px;
    height: 3px;
    background: #FFF;
    border-radius: 25px;
    margin: 4px 0;
    transition: width 0.3s ease-in-out;
}

.menuIcon .icon span:nth-of-type(2) {
    width: 17px;
}

.menuIcon .icon span:nth-of-type(3) {
    width: 9px;
}

.menuIcon:hover .icon span {
    width: 24px;
}

.menuIcon.open .icon span {
    width: 24px;
}

.menuNav {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateX(-100%);
    transition: transform 0.2s ease-in-out;
    animation: navOpening 0.2s ease-in-out forwards;
}

.menuNav .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(0);
    -webkit-backdrop-filter: blur(0);
    background-color: rgba(0, 0, 0, 0);
    transition: none;
    transform: translateX(100%);
    animation: overlayOpening 0.2s ease-in-out forwards;
}

.menuNav .navContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 80px);
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    max-width: 400px;
    background-color: #142435;
    font-size: 16px;
}

@media screen and (max-width: 1024px) {
    .menuNav .navContainer {
        max-width: 300px;
    }
}

.menuNav .newConversation {
    top: 9px;
    right: 16px;
    cursor: pointer;
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    gap: 8px;
    font-size: 23px;
    position: absolute !important;
}

.menuNav ul.navListContainer .conversationsListContainer .conversationGroup {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    gap: 4px;
}

.menuNav ul.navListContainer {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 32px;
    padding: 80px 0 20px 0;
}

.menuNav ul.navListContainer .conversationsListContainer {
    width: 100%;
    height: calc(100% - 64px - 21px - 19px - 60px);
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 32px;
}

.menuNav ul.navListContainer .conversationsListContainer .conversationGroup h4 {
    padding: 0 25px;
    font-size: 14px;
    font-weight: 300;
}

.menuNav ul.navListContainer .conversationsListContainer .conversationGroup>ul {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    padding: 0 16px 0 15px;
}

.menuNav ul.navListContainer .conversationsListContainer .conversationGroup>ul>li {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 1;
    padding-left: 10px;
    height: 40px;
    font-weight: 400;
    color: #FFF;
}

.menuNav ul.navListContainer .conversationsListContainer .conversationGroup>ul>li:hover {
    background-color: hsl(215, 42%, 18%);
}

.menuNav ul.navListContainer .conversationsListContainer .conversationGroup>ul>li.active {
    background-color: hsl(215, 42%, 22%);
}

.menuNav ul.navListContainer .conversationsListContainer .conversationGroup>ul>li>p {
    cursor: pointer;
    width: calc(100% - 50px);
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.menuNav ul.navListContainer .conversationsListContainer .conversationGroup>ul>li.conversationItem {
    border-radius: 8px;
}

.menuNav ul.navListContainer .conversationsListContainer .conversationGroup>ul>li.conversationItem button {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 30px;
    width: 50px;
}

.menuNav ul.navListContainer .conversationsListContainer .conversationGroup>ul>li .conversationDetails {
    position: absolute;
    top: 90%;
    right: 0;
    transform: translateX(67%);
    width: 160px;
    height: max-content;
    background-color: hsl(211, 45%, 10%);
    padding: 8px;
    border-radius: 8px;
    z-index: 2;
}

@media screen and (max-width: 1024px) {
    .menuNav ul.navListContainer .conversationsListContainer .conversationGroup>ul>li .conversationDetails {
        width: 120px;
    }
}

.menuNav ul.navListContainer .conversationsListContainer .conversationGroup>ul>li .conversationDetails ul li {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    padding: 12px;
    width: 100%;
    border-radius: 8px;
    font-weight: 400;
}

.menuNav ul.navListContainer .conversationsListContainer .conversationGroup>ul>li .conversationDetails ul li:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

.menuNav ul.navListContainer .conversationsListContainer .conversationGroup>ul>li .conversationDetails ul li.delete {
    color: var(--color-red);
}

.menuNav ul.navListContainer .conversationsListContainer .conversationGroup>ul>li .conversationDetails ul li.delete:hover {
    background-color: var(--color-red-alpha);
}

.menuNav ul.navListContainer .conversationsListContainer .conversationGroup>ul>li {
    display: flex;
}

.menuNav .conversationItem button {
    display: flex;
}

.menuNav footer {
    width: 100%;
}

.menuNav footer ul.profileContainer {
    width: 100%;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    position: relative;
}

.menuNav footer ul.profileContainer>li {
    width: 100%;
}

.menuNav footer ul.profileContainer>li>button {
    width: 100%;
    padding: 0 25px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    gap: 16px;
    color: #fff;
    font-weight: 400;
}

.menuNav footer ul.profileContainer>li>button i {
    width: 34px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: var(--color-primary-alpha);
    color: var(--color-primary);
}

.menuNav footer ul.profileContainer .profileDetails {
    position: absolute;
    top: -16px;
    transform: translateY(-100%) translateX(-50%);
    left: 50%;
    width: calc(100% - 50px);
    height: max-content;
    background-color: hsl(211, 45%, 10%);
    padding: 8px;
    border-radius: 8px;
}

.menuNav footer ul.profileContainer .profileDetails ul {
    display: flex;
    flex-direction: column-reverse;
}

.menuNav footer ul.profileContainer .profileDetails ul li {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    padding: 12px;
    width: 100%;
    border-radius: 8px;
    font-weight: 400;
}

.menuNav footer ul.profileContainer .profileDetails ul li:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

.menuNav footer ul.profileContainer .profileDetails ul li button {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
}

.menuNav footer ul.profileContainer .profileDetails ul li.logout {
    color: var(--color-red);
}

.menuNav footer ul.profileContainer .profileDetails ul li.logout:hover,
.menuNav footer ul.profileContainer .profileDetails ul li.logout:hover {
    background-color: var(--color-red-alpha);
}

@keyframes navOpening {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes overlayOpening {
    0% {
        backdrop-filter: blur(0);
        -webkit-backdrop-filter: blur(0);
        background-color: rgba(0, 0, 0, 0);
        transform: translateX(100%);
    }

    100% {
        background-color: rgba(0, 0, 0, 0.4);
        backdrop-filter: blur(2px);
        -webkit-backdrop-filter: blur(2px);
        transform: translateX(0);
    }
}
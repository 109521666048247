.mainDashboard {
    background-color: var(--color-black);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    padding: 5%;
    overflow-y: auto;

    .closeButton {
        position: absolute;
        top: 16px;
        right: 16px;
        cursor: pointer;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
            background-color: hsl(211, 45%, 18%);
        }
    }

    @media screen and (max-width: 1024px) {
        padding-top: 80px;
    }
}

.dashboardContainer {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: repeat(5, 1fr);
    gap: 40px;

    @media screen and (max-width: 1024px) {
        display: flex;
        flex-direction: column;
    }
}

.card {
    background-color: hsl(215, 42%, 12%);
    border-radius: 8px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    label {
        font-size: 0.825rem;
        text-transform: uppercase;
        letter-spacing: 1px;
        color: hsl(211, 20%, 50%);
        font-weight: 600;
        position: absolute;
        display: block;
        top: 24px;
        left: 24px;
        z-index: 1;
    }

    @media screen and (max-width: 1024px) {
        width: 100%;
        text-align: center;

        label {
            position: static;
            margin-bottom: 16px;
        }
    }
}

.div1 {
    grid-column: span 2 / span 2;
    grid-row: span 3 / span 3;

    video {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        object-fit: contain;
        pointer-events: none;
        z-index: 0;
        mix-blend-mode: color-dodge;
        opacity: 0.4;
    }

    .profileValue {
        font-size: clamp(1.5rem, 3vw, 5rem);
        text-transform: capitalize;
    }
}

.div2 {
    grid-column: span 2 / span 2;
    grid-row: span 2 / span 2;
    grid-column-start: 1;
    grid-row-start: 4;

    .moodScoreValue {
        font-size: clamp(1.5rem, 3vw, 5rem);

        &.pos {
            color: var(--color-green);
        }

        &.neg {
            color: var(--color-red);
        }
    }
}

.div3 {
    grid-column: span 3 / span 3;
    grid-row: span 3 / span 3;
    grid-column-start: 3;
    grid-row-start: 1;

    .wordCloud {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        gap: 10%;
        padding: 0 10%;

        .wordCloudItem {
            white-space: nowrap;
        }
    }
}

.div4 {
    grid-column: span 2 / span 2;
    grid-row: span 5 / span 5;
    grid-column-start: 6;
    grid-row-start: 1;
    padding: 80px 24px;
    overflow-y: auto;
    overflow-x: hidden;
    align-items: flex-start;
    justify-content: flex-start;

    .conversationGroup {
        &:not(:last-child) {
            margin-bottom: 24px;
        }

        h4 {
            font-size: 14px;
            font-weight: 300;
            margin-bottom: 8px;
            font-weight: 600;
            color: hsl(211, 20%, 50%);
        }
    }

    ul {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 8px;

        li {
            border: 1px solid hsl(211, 20%, 20%);
            border-radius: 8px;
            padding: 16px;
            width: 100%;
        }
    }
}

.div5 {
    grid-column: span 3 / span 3;
    grid-row: span 2 / span 2;
    grid-column-start: 3;
    grid-row-start: 4;
    align-items: flex-start;
    padding-top: 50px;
}